<template>
  <Fragment>
    <v-row class="mt-4">
      <v-col cols="4" md="4" v-if="haveRoles(['ROLE_SUPER_ADMIN'])">
        <cerrarSessionesVue />
      </v-col>
      <v-col
        cols="4"
        md="4"
        v-if="haveRoles(['ROLE_SUPER_ADMIN', 'ROLE_NOTIFICACIONES_CREAR'])"
      >
        <enviarNotificacionesVue />
      </v-col>
      <v-col
        cols="4"
        md="4"
        v-if="haveRoles(['ROLE_SUPER_ADMIN', 'ROLE_CORREOS_ENVIAR'])"
      >
        <enviarCorreois />
      </v-col>
      <v-col cols="4" md="4" v-if="haveRoles(['ROLE_SUPER_ADMIN'])">
        <gestionLimiteDescargas />
      </v-col>
      <v-col cols="4" md="4" v-if="haveRoles(['ROLE_SUPER_ADMIN'])">
        <auditoriaUsuarioSubasta />
      </v-col>
    </v-row>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import cerrarSessionesVue from "./cerrarSessiones.vue";
import enviarNotificacionesVue from "./enviarNotificaciones.vue";
import enviarCorreois from "./enviarCorreois.vue";
import GenerarMigracionDisponibilidad from "./generarMigracionDisponibilidad.vue";
import gestionLimiteDescargas from "./gestionLimiteDescargas.vue";
import auditoriaUsuarioSubasta from "./auditoriaUsuarioSubasta.vue";
export default {
  name: "AdminActions",
  components: {
    cerrarSessionesVue,
    Fragment,
    enviarNotificacionesVue,
    enviarCorreois,
    GenerarMigracionDisponibilidad,
    gestionLimiteDescargas,
    auditoriaUsuarioSubasta,
  },
};
</script>
