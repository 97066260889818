var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isProvider)?_c('DashboardPerfil'):_c('v-container',[_c('v-card',{staticClass:"px-0 py-0"},[_c('v-card-title',{staticClass:"px-0 py-0"},[_c('div',{staticClass:"bgCardHeader d-flex align-center justify-center",staticStyle:{"min-height":"250px","width":"100%"}},[_c('v-img',{staticClass:"mt-8 mb-10 mx-auto",attrs:{"contain":"","src":require("@/assets/img/comprasal_oficial.png"),"max-width":"350"}})],1)]),_c('v-divider'),_c('v-card-text',{staticClass:"px-4 pt-0 pb-4",staticStyle:{"min-height":"500px"}},[_c('h6',{staticClass:"text-h5 mt-4 primary--text font-weight-bold text-uppercase text-center"},[_vm._v(" Bienvenido a tu portal COMPRASAL ")]),(
          _vm.haveRoles([
            'ROLE_TECNICO_DIRECCION_COMPRAS',
            'ROLE_UACI',
            'ROLE_UACI_TECNICO',
            'ROLE_OFICIAL_CUMPLIMIENTO',
            'ROLE_AUDITORIA_GLOBAL',
          ])
        )?_c('PlanificacionMensualPAC',{staticClass:"mt-4",attrs:{"hide-header":"","id-institucion":_vm.idInstitucionUsuario,"filtros-sticky":false},scopedSlots:_vm._u([(_vm.haveRoles(['ROLE_UACI']))?{key:"prepend-actions",fn:function({ disabled, anioFiscal }){return [_c('CardComponent',{attrs:{"disabled":disabled,"anio-fiscal":anioFiscal}})]}}:null],null,true)}):_vm._e(),_c('adminActions',{staticClass:"mt-4"}),(
          (_vm.haveRoles([
            'ROLE_HERRAMIENTA_ELIMINAR_ETAPAS_CONTRATO',
            'ROLE_CM_HERRAMIENTA_CATALOGO_ELECTRONICO',
          ]) &&
            !_vm.haveRole('ROLE_DIRECCION_COMPRAS')) ||
          _vm.haveRole('ROLE_SUPER_ADMIN')
        )?_c('ObtenerProcesoContrato',{staticClass:"mt-4"}):_vm._e(),(
          (_vm.haveRole('ROLE_CM_HERRAMIENTA_CATALOGO_ELECTRONICO') &&
            !_vm.haveRole('ROLE_DIRECCION_COMPRAS')) ||
          _vm.haveRole('ROLE_SUPER_ADMIN')
        )?_c('CMDetalleSolicitudCompra',{staticClass:"mt-4"}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }